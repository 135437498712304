import { Container, Row, Col } from "react-bootstrap";

export default function WhyUs() {
  return (
    <>
      <Container id="why-us" style={{ scrollMarginTop: "10rem" }}>
        <Row className="justify-content-center text-center">
          <Col xs={4} md={1} className="p-3">
            <img
              src="img/why1.png"
              className="img-fluid mb-2"
              width={80}
              alt=""
            />
            <br />
            <span className="mt-4">Durabilidad</span>
          </Col>
          <Col xs={4} md={1} className="align-self-center">
            <Divider />
          </Col>
          <Col xs={4} md={1} className="p-3">
            <img
              src="img/why2.png"
              className="img-fluid mb-2"
              width={80}
              alt=""
            />
            <br />
            <span className="mt-4">Calidad</span>
          </Col>
          <Col xs={4} md={1} className="d-none d-md-block align-self-center">
            <Divider />
          </Col>
          <Col xs={4} md={1} className="p-3">
            <img
              src="img/why3.png"
              className="img-fluid mb-2"
              width={80}
              alt=""
            />
            <br />
            <span className="mt-4">Seguridad</span>
          </Col>
          <Col xs={4} md={1} className="align-self-center">
            <Divider />
          </Col>
          <Col xs={4} md={1} className="p-3">
            <img
              src="img/why4.png"
              className="img-fluid mb-2"
              width={80}
              alt=""
            />
            <br />
            <span className="mt-4">Confianza</span>
          </Col>
        </Row>
      </Container>
      <div className="mt-4 mx-0">
        <Row className="text-center gx-0">
          <Col xs={12} md={6}>
            <div
              style={{
                backgroundColor: "var(--terciary-color)",
                padding: "1rem",
                color: "white",
              }}
            >
              <h5 className="fw-bold">Durabilidad</h5>
              <p>
                Las garrafas de YPF GAS se comercializan con precintos de
                seguridad que permiten que nuestro producto llegue sin
                alteraciones a los usuarios, otorgando la máxima durabilidad
              </p>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              style={{
                padding: "1rem",
              }}
            >
              <h5 className="fw-bold">Calidad</h5>
              <p>
                Nuestros productos están desarrollados con tecnología y procesos
                que aseguran el cumplimiento de los más altos estándares de
                calidad y respeto por el medio ambiente
              </p>
            </div>
          </Col>
        </Row>
        <Row className="text-center gx-0">
          <Col xs={12} md={6}>
            <div
              style={{
                backgroundColor: "var(--terciary-color)",
                padding: "1rem",
                color: "white",
              }}
            >
              <h5 className="fw-bold">Seguridad</h5>
              <p>
                En YPF GAS estamos comprometidos con la seguridad, por ello
                todas las actividades que desarrollamos se realizan cumpliendo
                la normativa vigente y las disposiciones de la Secretaría de
                Energía de la Nación
              </p>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              style={{
                padding: "1rem",
              }}
            >
              <h5 className="fw-bold">Confianza</h5>
              <p>
                Cuando llevas a tu casa garrafas de YPF GAS, contás con el
                respaldo de una empresa líder, con años de trayectoria en el
                mercado, comprometida con ofrecer productos y servicios de
                excelencia
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

function Divider() {
  return (
    <hr style={{ border: "2px solid var(--primary-color)", opacity: 1 }} />
  );
}

import { Container, Row, Col } from "react-bootstrap";

export default function PaymentMethods() {
  const cardStyle = {
    boxShadow: "9px 10px 20.1px 0px rgba(0, 0, 0, 0.20)",
    padding: "2rem",
    width: "80vw",
  };
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col
            xs={6}
            md={5}
            className="d-flex align-items-center justify-content-center"
          >
            <img width={70} alt="App YPF" src="img/ypf.png"></img>
            <span className="ms-3">App YPF</span>
          </Col>
          <Col
            xs={6}
            md={5}
            className="d-flex align-items-center justify-content-center"
          >
            <img width={70} alt="App YPF" src="img/mercadopago.png"></img>
            <span className="ms-3">Mercado Pago</span>
          </Col>
        </Row>
      </Container>
      <Container
        style={cardStyle}
        className="d-flex flex-column mt-5 text-center"
      >
        <h3 className="text-danger fs-1">Importante</h3>
        <span className="fs-4">
          Los pagos se realizan en el momento de la compra
          <br /> <strong>NO recibimos pagos por adelantado</strong>
        </span>
        <span className="fw-light mt-3">
          Evite estafas telefónicas y por redes sociales
        </span>
        <span className="fw-light">
          Para más información comunicarse a:{" "}
          <a
            style={{ textDecoration: "none" }}
            href="mailto:atencionalcliente@juangas.com.ar"
          >
            atencionalcliente@juangas.com.ar
          </a>
        </span>
      </Container>
    </>
  );
}

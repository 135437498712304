import { Col, Container, Row } from "react-bootstrap";

export default function BottledGas() {
  return (
    <Container id="bottled-gas" style={{ scrollMarginTop: "10rem" }}>
      <Row className="justify-content-center">
        <Col xs={6} md={3} className="text-center">
          <img src="img/bottle1.png" className="img-fluid" alt="" />
          <br />
          <span>10 y 15kg</span>
        </Col>
        <Col xs={6} md={3} className="text-center">
          <img src="img/bottle2.png" className="img-fluid" alt="" />
          <br />
          <span>45kg</span>
        </Col>
        <Col xs={12} md={3} className="text-center">
          <img src="img/bottle3.png" className="img-fluid" alt="" />
          <br />
          <span>Envase liviano</span>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} md={6} className="mt-5 text-center">
          <span className="text-secondary">
            Nuestras garrafas de butano son la mejor opción para cocinar y
            calefaccionar tu hogar ya que están desarrolladas con tecnología y
            procesos que aseguran el cumplimiento de la normativa vigente y los
            más altos estándares de calidad, seguridad y respeto por el medio
            ambiente.
          </span>
        </Col>
      </Row>
    </Container>
  );
}

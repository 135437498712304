import { Container, Row } from "react-bootstrap";

export default function SalesMethods({ classes }) {
  const methods = [
    {
      id: 1,
      name: "Reparto",
      schedule: "Lunes a Viernes 9 a 16hs <br> Sábado 9 a 12hs",
      image: "img/truck.png",
      calltoActionText: "Pedido por Whatsapp",
      callToActionCallback: () => {
        window.open("https://api.whatsapp.com/send?phone=5492645650502");
      },
    },
    {
      id: 2,
      name: "Presencial",
      schedule: "Lunes a Viernes 9 a 16hs <br> Sábado 9 a 12hs",
      image: "img/location.png",
      calltoActionText: "Ver ubicación",
      footNote:
        "Calle Gral. Mosconi antes de calle 5, <br /> Rawson. Lateral de Ruta 40",
      callToActionCallback: () => {
        window.location = "#location";
      },
    },
  ];

  const cardStyle = {
    boxShadow: "9px 10px 20.1px 0px rgba(0, 0, 0, 0.20)",
    paddingTop: "5rem",
    height: "25rem",
    width: "25rem",
  };

  const buttonStyle = {
    backgroundColor: "var(--primary-color)",
    color: "white",
    border: "none",
    padding: "0.5rem",
    width: "70%",
  };

  return (
    <Container
      className={classes}
      id="sales"
      style={{ scrollMarginTop: "10rem" }}
    >
      <Row className="m-auto justify-content-evenly">
        {methods.map((method) => (
          <div
            className="col-12 col-md-6 col-lg-4 mb-5  d-flex flex-column justify-content-start"
            style={{ ...cardStyle }}
          >
            <div className="text-center ">
              <img src={method.image} alt="" className="img-fluid" />
              <h3 className="mt-3 fw-bold">{method.name}</h3>
              <p
                style={{ color: "grey" }}
                dangerouslySetInnerHTML={{ __html: method.schedule }}
              ></p>
              <button style={buttonStyle} onClick={method.callToActionCallback}>
                {method.calltoActionText}
              </button>
            </div>
            <div className="text-center">
              {method.footNote ? (
                <p
                  style={{
                    color: "grey",
                    fontSize: "0.8rem",
                    padding: "0.5rem",
                  }}
                  dangerouslySetInnerHTML={{ __html: method.footNote }}
                ></p>
              ) : null}
            </div>
          </div>
        ))}
      </Row>
    </Container>
  );
}

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./PageNavbar.css";
import { useEffect, useState } from "react";

function PageNavbar() {
  const location = window.location.hash;

  const [navbarBg, setNavbarBg] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setNavbarBg(true);
      } else {
        setNavbarBg(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navbarBg]);

  return (
    <>
      {["md"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className={`mb-3 page-navbar ${navbarBg ? "navbar-scrolled" : ""}`}
          fixed="top"
        >
          <Container fluid>
            <Navbar.Brand href="/">
              <img
                src="img/logo.png"
                className="img-fluid"
                width={150}
                alt=""
              />
            </Navbar.Brand>
            <Navbar.Toggle
              onClick={handleShow}
              aria-controls={`offcanvasNavbar-expand-${expand}`}
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={showOffcanvas}
              onHide={handleClose}
              className="bg-canvas"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <Navbar.Brand href="/">
                    <img
                      src="img/logo.png"
                      className="img-fluid"
                      width={70}
                      alt=""
                    />
                  </Navbar.Brand>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end text-center flex-grow-1 pe-3">
                  <Nav.Link
                    onClick={handleClose}
                    className={location === "#sales" ? "active" : ""}
                    href="/#sales"
                  >
                    Venta
                  </Nav.Link>
                  <Nav.Link
                    onClick={handleClose}
                    className={location === "#offers" ? "active" : ""}
                    href="/#offers"
                  >
                    Promociones
                  </Nav.Link>
                  <Nav.Link
                    onClick={handleClose}
                    className={location === "#why-us" ? "active" : ""}
                    href="/#why-us"
                  >
                    ¿Por qué elegirnos?
                  </Nav.Link>
                  <Nav.Link
                    onClick={handleClose}
                    className={location === "#bottled-gas" ? "active" : ""}
                    href="/#bottled-gas"
                  >
                    Gas Envasado
                  </Nav.Link>
                  <Nav.Link
                    onClick={handleClose}
                    className={location === "#security" ? "active" : ""}
                    href="/#security"
                  >
                    Seguridad
                  </Nav.Link>
                  <Nav.Link
                    onClick={handleClose}
                    className={location === "#contact" ? "active" : ""}
                    href="/#contact"
                  >
                    Contacto
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default PageNavbar;

import { useEffect, useState } from "react";

export default function Offers() {
  return ResponsiveImageRow();
}

const ResponsiveImageRow = () => {
  const images = [
    { src: "img/offer1.png", alt: "Image 1", width: 328 },
    { src: "img/offer2.png", alt: "Image 2", width: 228 },
    { src: "img/offer3.png", alt: "Image 3", width: 231 },
  ];

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="container-fluid"
      id="offers"
      style={{ scrollMarginTop: "10rem" }}
    >
      <div
        className={`row flex-nowrap overflow-auto ${
          isLargeScreen ? "justify-content-center" : ""
        }`}
      >
        {images.map((image, index) => (
          <div key={index} className="col-auto px-2">
            <img
              src={image.src}
              alt={image.alt}
              className="img-fluid"
              style={{ height: "328px", width: `${image.width}px` }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

import { Container, Row, Col } from "react-bootstrap";

export default function Recommendations() {
  return (
    <>
      <Container
        className="mt-5 mb-5"
        id="security"
        style={{ scrollMarginTop: "10rem" }}
      >
        <Row className="justify-content-center">
          <Col xs={6} md={3}>
            <DetailCombo
              img="img/recommendation1.png"
              title="Controlar"
              description="Que el envase no se <br> encuentre vencido"
            />
          </Col>
          <Col xs={6} md={3}>
            <DetailCombo
              img="img/recommendation2.png"
              title="No ubicar"
              description="La garrafa en interiores <br> ni sótanos"
            />
          </Col>
        </Row>

        <Row className="text-primary text-center mt-3">
          <div>
            <h4 className="fw-bold">Seguridad</h4>
            <h4 className="fw-bold">Antes de conectar la garrafa</h4>
          </div>
        </Row>

        <Row className="justify-content-center mt-5">
          <Col xs={6} md={3}>
            <DetailCombo
              img="img/safety1.png"
              title="Cerrar"
              description="Todas las llaves de paso de los <br> artefactos conectados"
            />
          </Col>
          <Col xs={6} md={3}>
            <DetailCombo
              img="img/safety2.png"
              title="Verificar"
              description="El estado de la manguera, las <br> abrazaderas y el regulador"
            />
          </Col>
          <Col xs={6} md={3}>
            <DetailCombo
              img="img/safety3.png"
              title="Comprobar"
              description="Que no haya pérdidas con una <br> mezcla de agua jabonosa"
            />
          </Col>
          <Col xs={6} md={3}>
            <DetailCombo
              img="img/safety4.png"
              title="Leer"
              description="Siempre el rótulo de recomendaciones <br> de uso que trae el envase"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

function DetailCombo({ img, title, description }) {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img src={img} width={85} className="img-fluid" alt="" />
      <h6 className="mt-2">{title}</h6>
      <p
        className="text-secondary text-center"
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>
    </div>
  );
}

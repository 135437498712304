import { Container, Row } from "react-bootstrap";
import "./PropertiesTable.css";

export default function PropertiesTable() {
  return (
    <Container className="text-center d-flex justify-content-center">
      <table className="glp-table w-100 w-md-50">
        <thead>
          <tr>
            <th>Punto de ebullición a presión atmosférica</th>
            <th>-42°C</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Poder calorífico inferior</td>
            <td>11.000 kcal/kg</td>
          </tr>
          <tr>
            <td>Poder calorífico superior</td>
            <td>11.900 kcal/kg</td>
          </tr>
          <tr>
            <td>Densidad de líquido</td>
            <td>515 kg/m3</td>
          </tr>
          <tr>
            <td>Volúmen de aire para combustionar una unidad de vol. de gas</td>
            <td>23 u. De aire</td>
          </tr>
        </tbody>
      </table>
    </Container>
  );
}

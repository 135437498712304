import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";
import Subtitle from "../Subtitle";

function Footer() {
  return (
    <>
      <footer className="text-center text-secondary">
        <Container className="mt-3 mb-5">
          <Subtitle text={"Contacto"}></Subtitle>
        </Container>
        <Container id="contact">
          <Row>
            <Col xs={12} md={6} className="mb-3 d-flex justify-content-start">
              <div className="d-flex text-start">
                <img
                  src="img/footer_phone.png"
                  className="img-fluid"
                  width={50}
                  alt=""
                />
                <span className="ms-3">
                  4288777 interno 115/116 <br /> Contacto Oficial
                </span>
              </div>
            </Col>
            <Col xs={12} md={6} className="mb-3 d-flex justify-content-start">
              <div className="d-flex text-start">
                <img
                  src="img/footer_email.png"
                  className="img-fluid"
                  width={50}
                  alt=""
                />
                <span className="ms-3">
                  <a href="mailto:atencionalcliente@juangas.com.ar">
                    atencionalcliente@juangas.com.ar
                  </a>{" "}
                  <br /> Correo Oficial
                </span>
              </div>
            </Col>
            <Col xs={12} md={6} className="mb-3 d-flex justify-content-start">
              <div className="d-flex text-start">
                <img
                  src="img/footer_wsp.png"
                  className="img-fluid"
                  width={50}
                  alt=""
                />
                <span className="ms-3">
                  264 565-0502 <br /> WhatsApp Oficial
                </span>
              </div>
            </Col>
            <Col xs={12} md={6} className="mb-3 d-flex justify-content-start">
              <div className="d-flex text-start">
                <img
                  src="img/footer_web.png"
                  className="img-fluid"
                  width={50}
                  alt=""
                />
                <span className="ms-3">
                  www.juangas.com.ar <br />
                  Sitio Oficial
                </span>
              </div>
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-start">
              <div className="d-flex text-start">
                <img src="img/footer_location.png" height={50} alt="" />
                <span className="ms-3">
                  Ubicación <br /> Calle Gral. Mosconi S/N antes de calle 5,
                  <br />
                  Rawson. Lateral de Ruta 40.
                </span>
              </div>
            </Col>
            <Col xs={12} md={6} className="d-flex jcc-sm">
              <div className="d-flex text-center">
                <a href="https://www.instagram.com/juangas.ypf/">
                  <img src="img/footer_insta.png" height={50} alt="" />
                </a>
                <a href="https://www.facebook.com/p/JUAN-GAS-100078643936663/">
                  <img
                    src="img/footer_facebook.png"
                    className="ms-3"
                    height={50}
                    alt=""
                  />
                </a>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="mb-3 mt-4">
          <img src="img/logo.png" className="img-fluid" width={200} alt="" />
          <br />
          <span>© 2024 juangas.com.ar. Todos los derechos reservados.</span>
        </Container>
      </footer>
    </>
  );
}

export default Footer;

import "./App.css";
import PageNavbar from "./components/navbar/PageNavbar";
import Footer from "./components/footer/Footer";
import HeaderBanner from "./components/header-banner/HeaderBanner";
import { Container, Row, Col } from "react-bootstrap";
import Subtitle from "./components/Subtitle";
import SalesMethods from "./components/SalesMethods";
import PaymentMethods from "./components/PaymentMethods";
import Offers from "./components/Offers";
import WhyUs from "./components/WhyUs";
import BottledGas from "./components/BottledGas";
import Recommendations from "./components/Recommendations";
import PropertiesTable from "./components/PropertiesTable";

function App() {
  return (
    <>
      <PageNavbar></PageNavbar>
      <HeaderBanner></HeaderBanner>

      <Container className="mt-5 mb-5">
        <Subtitle text={"Formas de venta"}></Subtitle>
      </Container>
      <SalesMethods classes={"mt-5"}></SalesMethods>

      <Container className="mt-5 mb-5">
        <Subtitle text={"Formas de pago"}></Subtitle>
      </Container>
      <PaymentMethods></PaymentMethods>

      <Container className="mt-5 mb-5">
        <Subtitle text={"Promociones"}></Subtitle>
      </Container>
      <Container className="">
        <Offers></Offers>
      </Container>

      <Container className="mt-5 mb-5">
        <Subtitle text={"¿Por qué elegirnos?"}></Subtitle>
      </Container>
      <WhyUs></WhyUs>

      <Container className="mt-5 mb-5">
        <Subtitle text={"Gas Envasado"}></Subtitle>
      </Container>
      <BottledGas></BottledGas>

      <Container className="mt-5 mb-5">
        <Subtitle text={"Recomendaciones para una energía segura"}></Subtitle>
      </Container>
      <Recommendations></Recommendations>

      <Container className="mt-5 mb-5">
        <Subtitle
          text={"Tabla de propiedades del GLP Propano Comercial"}
        ></Subtitle>
      </Container>
      <PropertiesTable></PropertiesTable>

      <Container className="mt-5 mb-5" id="location">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3398.603433067568!2d-68.52636752427622!3d-31.589921074182197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x96813ffbaa2f74f1%3A0xe4c8dae902bbf697!2sJuan%20Gas%20(Gas%20Envasado%20YPF)%20Gesti%C3%B3n%20y%20Negocios!5e0!3m2!1ses!2sar!4v1726026387984!5m2!1ses!2sar"
          width="100%" // Changed width to be responsive
          height="450"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Container>
      <Footer></Footer>
      <a
        href="https://api.whatsapp.com/send?phone=5492645650502"
        className="float"
        target="_blank"
      >
        <i className="fa fa-whatsapp my-float"></i>
      </a>
    </>
  );
}

export default App;

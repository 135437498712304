import { useEffect, useState } from "react";
import "./HeaderBanner.css";

function HeaderBanner() {
  const [image, setImage] = useState(
    window.innerWidth > 768 ? "img/banner.png" : "img/hero-ypf-gas.jpg"
  );

  useEffect(() => {
    const handleResize = () => {
      setImage(
        window.innerWidth > 768 ? "img/banner.png" : "img/hero-ypf-gas.jpg"
      );
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="crop-container">
        <img src={image} alt="" className="img-fluid image-banner" />
        <h2 className="banner-heading">
          Distribuidor Oficial de <br /> YPF Gas
        </h2>
      </div>
    </>
  );
}

export default HeaderBanner;
